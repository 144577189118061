<template>
  <div>
    <button
      class="appearance-none   w-full text-center font-semibold rounded py-2 px-4 "
      :class="
        !isCamo
          ? 'border-gunmetal-400 border-2 text-gray-700 hover:bg-gray-100'
          : ''
      "
      @click="showModal('', 'PauseButton', subscription)"
    >
      {{ subscription.ispaused ? 'Extend pause...' : label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    subscription: {
      type: Object,
      default: () => {}
    },
    redirect: {
      type: String
    },
    label: {
      type: String,
      default: 'Pause this box...'
    },
    limitDays: {
      type: Number,
      default: 60
    },
    isCamo: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showModal: function(title, component, data) {
      data.limitDays = this.limitDays;
      this.$store.commit('show_modal', {
        title: title,
        component: component,
        data: data,
        redirect: this.redirect
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
