import { render, staticRenderFns } from "./SkipButton.vue?vue&type=template&id=2d7f656c&scoped=true"
import script from "./SkipButton.vue?vue&type=script&lang=js"
export * from "./SkipButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d7f656c",
  null
  
)

export default component.exports