<template>
  <div
    class="flex space-x-2 items-center"
    @click="showModal('', 'Payday', { subscription, redirect })"
  >
    <div v-if="!label" class="flex space-x-2 items-center">
      <font-awesome-icon class="text-base" :icon="['fal', 'calendar-day']" />
      <div>
        {{
          subscription.payday
            ? 'Update payment date'
            : 'or choose a payment date...'
        }}
      </div>
    </div>
    <div v-else class="w-full">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'PaydayUpdate',
  props: {
    subscription: {
      type: Object
    },
    label: {
      type: String
    },
    redirect: {
      type: String
    }
  },
  methods: {
    showModal(title, component, data) {
      this.$store.commit('show_modal', {
        title: title,
        component: component,
        data: data
      });
    }
  },
  mounted() {
    // check the url search params, if action = AddPaymentDate, show the modal
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('action') === 'AddPaymentDate') {
      this.showModal('', 'Payday', { subscription: this.subscription });
    }
  }
};
</script>

<style></style>
