<template>
  <div class="w-full md:w-1/2 mx-auto text-center space-y-4">
    <div
      v-if="canExtend && isOnTrial && !isPaused"
      class="border-2 bg-gray-100 border-dashed py-8 pb-10 rounded-lg px-4"
    >
      <div class="font-semibold text-md mb-2">
        Need a little bit longer on your trial?
      </div>

      <pause-button
        v-if="subscription"
        class="inline-block mt-6"
        label="Extend my trial"
        redirect="dashboard"
        :subscription="subscription"
        extend-trial
        :limit-days="14"
      />
    </div>
    <transition name="fade" mode="out-in">
      <div key="send-message-flow" v-if="!helpSuccess">
        <div class="text-2xl font-semibold mb-4 px-6 text-center">
          Can we help with anything?
        </div>
        <div class="mb-4">
          We'll reply <span class="underline">super fast</span>.
        </div>
        <div class="w-full">
          <textarea
            class="w-full no-max"
            v-model="helpMessage"
            placeholder="How can we help you?"
          ></textarea>
        </div>

        <div>
          <div
            v-if="helpError"
            class="text-red-400 p-4 border-2 border-red-400 mb-4 rounded"
          >
            There was an error sending this message. Please contact us at
            <a href="mailto:team@thecoolcatclub">team@thecoolcatclub</a> for
            help.
          </div>
          <form @submit.prevent="createHelpTicket" novalidate>
            <loading-button
              :is-loading="loadingHelp"
              :is-enabled="true"
              class="w-full mt-4"
              is-full
              secondary
            >
              <div>Send message</div>
            </loading-button>
          </form>
        </div>
      </div>
      <div v-else key="send-message-success">
        <div class="text-xl font-semibold mb-4 px-6 text-center">
          We've got your message
        </div>
        <div>Thanks for your message we will be in touch shortly.</div>
        <router-link
          class="mt-8 text-blue-400 underline block"
          :to="{ name: 'dashboard' }"
          >Back to your dashboard &rarr;
        </router-link>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div v-if="!helpSuccess">
        <div class="py-8">
          <div
            class="border-b-2 bg-gray-200 relative flex items-center justify-center"
          >
            <div class="bg-white p-4 py-1 text-gray-500 absolute">OR</div>
          </div>
        </div>
        <p class="pb-4">Continue with cancelling my subscription...</p>
        <div
          v-if="hasCredit"
          class="bg-yellow-100 border-yellow-400 border-2 p-4 rounded-lg mb-4"
        >
          You still have a
          <span class="font-semibold"
            >£{{ getCredit(subscription.credit) }}
          </span>
          credit to use off a box. Cancelling your subscription will remove this
          credit
        </div>

        <div class="divide-y-2 border-2 rounded-lg text-left overflow-hidden">
          <div
            :class="
              reason.index === selectedIndex ? `bg-${reason.color}-100` : ''
            "
            v-for="reason in cancelReasons"
            :key="`reason-${reason.index}`"
          >
            <label
              :for="`reason-${reason.index}`"
              class="w-full flex items-center p-3"
              ><input
                type="radio"
                class="mr-4"
                :id="`reason-${reason.index}`"
                :value="reason"
                v-model="selectedReason"
              />
              {{ reason.label }}</label
            >
            <div
              class="pt-0"
              v-if="selectedIndex === reason.index && reason.action"
            >
              <div
                class="space-y-2 p-4"
                :class="[reason.color ? `bg-${reason.color}-100 ` : '']"
              >
                <div class="flex space-x-5">
                  <div
                    class="w-1 flex-none flex rounded"
                    :class="reason.color ? `bg-${reason.color}-400 ` : ''"
                  ></div>
                  <div class="space-y-2">
                    <div
                      v-for="(paragraph, index) in reason.action.paragraphs"
                      :key="`action-paragraph-${index}`"
                    >
                      {{ paragraph }}
                    </div>
                    <div
                      v-if="
                        reason.action.tip &&
                        totalSubFood >= reason.action.tip.minAmount
                      "
                      class="italic text-gray-600 flex"
                    >
                      <div
                        v-for="(paragraph, index) in reason.action.tip
                          .paragraphs"
                        :key="`tip-paragraph-${index}`"
                      >
                        {{ paragraph }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="reason.action.cta"
                  class="flex w-full flex-col space-y-4 sm:flex-row pt-4 sm:space-x-4 sm:space-y-0"
                >
                  <router-link
                    v-if="!reason.action.cta.action"
                    :to="{
                      name: reason.action.cta.link,
                      params: { id: reason.action.cta.scrollId }
                    }"
                    class="text-white font-medium p-2 px-3 rounded text-center flex-grow"
                    :class="[reason.color ? `bg-${reason.color}-400 ` : '']"
                    >{{ reason.action.cta.label }}</router-link
                  >
                  <div v-else class="flex-grow text-center">
                    <div
                      class="flex-grow rounded text-center"
                      :class="[
                        reason.color ? `bg-${reason.color}-400 text-white ` : ''
                      ]"
                      v-if="
                        subscription && reason.action.cta.action === 'pause'
                      "
                    >
                      <div v-if="subscription.payday">
                        <skip-button
                          :subscription="subscription"
                          is-camo
                          redirect="dashboard"
                        />
                      </div>
                      <pause-button
                        v-else
                        class="flex w-full"
                        label="Pause"
                        redirect="dashboard"
                        :subscription="subscription"
                        is-camo
                      />
                    </div>
                    <div
                      v-if="
                        reason.action.cta.action === 'payday' &&
                        !subscription.isOnTrial &&
                        subscription.active
                      "
                    >
                      <div
                        class="text-white p-2 px-3 font-medium rounded text-center flex-grow"
                        :class="[reason.color ? `bg-${reason.color}-400 ` : '']"
                      >
                        <payday-update
                          :subscription="subscription"
                          :label="reason.action.cta.label"
                          redirect="dashboard"
                          class="w-full"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    @click="cancelSubscription"
                    class="p-2 px-3 text-red-500 text-center hover:bg-red-400 hover:text-white rounded cursor-pointer"
                  >
                    No thanks, just cancel
                  </div>
                </div>
              </div>
            </div>
            <div
              class="p-3 pt-0"
              v-if="
                reason.hasTextarea &&
                selectedIndex &&
                selectedIndex === reason.index
              "
            >
              <textarea
                class="is-full"
                v-model="reason.value"
                placeholder="Please let us know..."
              ></textarea>
            </div>
          </div>
        </div>

        <div
          v-if="this.error"
          class="text-red-400 p-4 border-2 border-red-400 mb-8"
        >
          There was an error cancelling your subscription. Please contact
          <a href="mailto:team@thecoolcatclub">team@thecoolcatclub</a> for help
        </div>
        <div
          v-if="this.showHelper"
          class="p-4 border-2 bg-yellow-100 border-yellow-400 mb-8 rounded-lg sm:px-20"
        >
          Please select a reason from above, it really helps us know where we
          need to improve
        </div>
        <div class="">
          <form @submit.prevent="cancelSubscription" novalidate>
            <loading-button
              :is-loading="loading"
              :is-enabled="true"
              class="w-full mt-4"
              is-full
              caution
            >
              <div>Cancel subscription</div>
            </loading-button>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import http from '@/http';
import PauseButton from '@/components/PauseButton';
import LoadingButton from '@/components/LoadingButton';
import { mapActions, mapGetters } from 'vuex';
import { toNumber } from 'lodash/fp';
import PaydayUpdate from '@/components/PaydayUpdate.vue';
import SkipButton from '@/components/SkipButton.vue';

export default {
  props: ['feedback'],
  components: {
    PauseButton,
    LoadingButton,
    PaydayUpdate,
    SkipButton
  },

  data() {
    return {
      error: false,
      loading: false,
      loadingHelp: false,
      helpSuccess: false,
      helpMessage: '',
      helpError: false,
      cancelReasons: [
        {
          label: 'My cat no longer likes their food',
          value: 'My cat no longer likes their food',
          index: 1,
          color: 'green',
          action: {
            paragraphs: [
              'Did you know we now have over 30 items of Wet & Dry Food!'
            ],
            cta: {
              label: 'View Our Latest Range',
              link: 'the food',
              scrollId: 'food-selection-start'
            },

            tip: {
              paragraphs: [
                ' Perhaps take a smaller order to try some new options!'
              ],
              minAmount: 45
            }
          }
        },
        {
          color: 'green',
          label: 'I’ve got too much food',
          value: 'I’ve got too much food',
          index: 6,
          action: {
            paragraphs: [
              'Did you know you can pause for up to 3 months whilst you catch up.',
              'We’ll also send you a reminder before any box goes out.'
            ],
            cta: {
              label: 'Pause',
              action: 'pause'
            }
          }
        },
        {
          label: 'It’s a little too costly for me',
          value: 'It’s a little too costly for me',
          index: 2,
          color: 'blue',
          action: {
            paragraphs: [
              'In case it helps... Try our Payday feature. Choose the same date each month you’d like your payment to go out'
            ],
            cta: {
              label: 'Set Payday Date',
              action: 'payday',
              link: 'the food'
            }
          }
        },

        {
          label: 'My cat has sadly passed away',
          value: 'My cat has sadly passed away',
          index: 5
        },
        {
          label: 'Other',
          value: '',
          index: 4,
          hasTextarea: true
        }
      ],
      selectedReason: null,
      showHelper: false
    };
  },
  computed: {
    ...mapGetters({
      subscription: 'getCurrentSubscription',
      currentUser: 'getCurrentUser'
    }),
    selectedIndex() {
      return this.selectedReason ? this.selectedReason.index : null;
    },
    reasonFormatted() {
      return `${this.selectedReason?.index}-${this.selectedReason?.value}`;
    },
    totalSubFood() {
      return this.subscription?.items.reduce((acc, item) => {
        return acc + item.wetFood;
      }, 0);
    },
    isPayday() {
      return this.subscription?.payday;
    },
    canExtend() {
      return this.subscription?.canExtendTrial;
    },
    isPaused() {
      return this.subscription?.ispaused;
    },
    isOnTrial() {
      return this.subscription?.isOnTrial;
    },
    hasCredit() {
      return this.subscription?.credit;
    }
  },
  watch: {
    selectedReason(newValue) {
      if (newValue) {
        this.showHelper = false;
      }
    }
  },
  methods: {
    ...mapActions({
      sendToNotion: 'sendToNotion',
      createTicket: 'createTicket'
    }),
    submitFeedback: function () {
      const dataFb = this.feedback;
      dataFb.stepOne = this.selectedReason.index;
      dataFb.stepTwo = this.selectedReason.value;

      http
        .put(`/me/cancel_questions`, {
          questions: JSON.stringify(dataFb)
        })

        .then(() => {
          const params = {
            name: 'cancel success',
            params: {}
          };

          if (this.selectedReason.index === 5) {
            params.params.passed = true;
          }
          params.params.isOnTrial = this.subscription.isOnTrial;
          params.params.cancelReason = this.selectedReason.value;
          this.$router.push(params).catch(err => {
            console.log(err);
          });
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    cancelSubscription: function () {
      if (!this.selectedReason) {
        this.showHelper = true;
      } else {
        this.loading = true;

        http
          .post(this.subscription.links.cancel)
          .then(() => {
            this.$store.dispatch('sendMpEvent', {
              event: 'Cancelled subscription - Success',
              properties: {
                location: this.$route.name,
                feedback: this.selectedReason.value,
                index: this.selectedReason.index,
                isTrial: this.subscription.isOnTrial,
                type: 'EVT'
              }
            });
            this.submitFeedback();

            if (
              this.selectedReason.index === 5 &&
              !this.subscription.isOnTrial
            ) {
              const data = {
                ID: {
                  number: toNumber(this.currentUser.id)
                },
                Name: {
                  title: [
                    {
                      text: {
                        content: this.currentUser.name
                      }
                    }
                  ]
                },
                Environment: {
                  rich_text: [
                    {
                      type: 'text',
                      text: {
                        content: process.env.NODE_ENV
                      }
                    }
                  ]
                }
              };
              this.sendToNotion({
                db: '953b86b68a2a44788ed4cb7c7d7540e2',
                data
              });
            }
          })
          .catch(error => {
            this.$store.dispatch('sendMpEvent', {
              event: 'Cancelled subscription - Error',
              properties: {
                location: this.$route.name,
                error: error,
                type: 'EVT'
              }
            });
            this.error = true;
            this.loading = false;
          });
      }
    },
    createHelpTicket() {
      this.loadingHelp = true;
      this.createTicket({
        subject: '!! Cancel Page Help !!',
        description: this.helpMessage,
        priority: 4,
        type: 'Cancel Help'
      })
        .then(() => {
          this.loadingHelp = false;
          this.helpSuccess = true;
        })
        .catch(() => {
          this.loadingHelp = false;
          this.helpError = true;
        });
    },
    getCredit(credit) {
      return credit / 100;
    }
  },
  mounted() {
    if (!this.subscription) {
      this.$store.dispatch('getSubscription').then(() => {
        if (this.isPayday || this.subscription?.isOnTrial) {
          const reason = this.cancelReasons.find(reason => reason.index === 2);
          delete reason.action;
          delete reason.color;
        }
      });
    }
    if (this.subscription?.isOnTrial) {
      this.cancelReasons.unshift({
        label: 'I want to try it first, will be back if my cat likes it',
        value: 'I want to try it first, will be back if my cat likes it',
        index: 7
      });
      const reason = this.cancelReasons.find(reason => reason.index === 6);
      //remove the too much food reason
      this.cancelReasons.splice(this.cancelReasons.indexOf(reason), 1);
    }
    if (this.isPayday || this.subscription?.isOnTrial) {
      const reason = this.cancelReasons.find(reason => reason.index === 2);
      delete reason.action;
      delete reason.color;
    }
  }
};
</script>

<style lang="css" scoped></style>
